// DashboardHeader.js

import React from 'react';
import './DashboardHeader.css';

const DashboardHeader = ({ currentTask }) => {
    return (
        <header className="dashboard-header">
            <h1 className="header-title">Your Dashboard</h1>
            <p className="dashboard-task">Current Task: {currentTask}</p>
            <div className="header-buttons">
                <a href="/edit-info" className="button-link">
                    <button className="manage-profile-button">Manage Profile</button>
                </a>
                <a href="/data-removals" className="button-link">
                    <button className="view-removals-button">View Removals</button>
                </a>
            </div>
        </header>
    );
};

export default DashboardHeader;
