// src/pages/DataLeakPage.js

import { useState, useEffect } from "react";
import "./DataLeakPage.css";
import MobileNavbar from "../components/MobileNavbar";
import Navbar from "../components/Navbar"; // Added for desktop navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faChevronDown, faChevronUp, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Footer } from "../components/footer"; // Ensure Footer is correctly exported

const DataLeakPage = () => {
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [breaches, setBreaches] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [expandedBreach, setExpandedBreach] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [infoCheckResult, setInfoCheckResult] = useState(null);

    // Constants for delays (in milliseconds)
    const BREACH_CHECK_DELAY = 10000; // 10 seconds

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setBreaches([]);
        setErrorMessage("");
        setExpandedBreach(null);
        setShowMore(false);

        try {
            // Check for email breaches
            const breachResponse = await fetch(
                "https://dashboard-api-server.fly.dev/api/check-email",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email }),
                }
            );

            if (breachResponse.status === 200) {
                const breachData = await breachResponse.json();
                setBreaches(breachData);
            } else if (breachResponse.status === 404) {
                setErrorMessage("No breaches found for this email.");
            } else if (breachResponse.status === 429) { // Handle rate limiting
                setErrorMessage("Rate limited while checking breaches. Please try again later.");
                setLoading(false);
                return; // Exit early to prevent further requests
            } else {
                setErrorMessage("Something went wrong while checking breaches. Please try again later.");
            }

            // If you have any additional actions after breach check, add them here
            // For example, triggering other checks or analytics
        } catch (error) {
            setErrorMessage("Error fetching breach information.");
        } finally {
            setLoading(false);
        }
    };

    const handleNameSubmit = (e) => {
        e.preventDefault();
        window.location.href = `https://app.cleandata.me/location?first=${encodeURIComponent(firstName)}&last=${encodeURIComponent(lastName)}`;
    };

    const toggleDetails = (breach) => {
        setExpandedBreach(expandedBreach === breach ? null : breach);
    };

    const breachesToShow = showMore ? breaches : breaches.slice(0, 4);

    return (
        <div className="data-leak-page">
            {/* Navbar */}
            {windowWidth > 768 && <Navbar />}
            {windowWidth <= 768 && <MobileNavbar />}

            {/* Content Wrapper */}
            <div className="content-wrapper">
                {/* Header Section */}
                <div className="data-leak-header card">
                    <h1>CleanData Email Leak Checker</h1>
                    <p>
                        Check if your email has been involved in any data breaches and protect your privacy.
                    </p>
                </div>

                {/* Eraser Icon */}
                <div className="eraser-icon-container">
                    <FontAwesomeIcon
                        icon={faEraser}
                        size="7x"
                        className="eraser-icon"
                        title="Data Leak Icon"
                    />
                </div>

                {/* Email Form */}
                <form className="data-leak-form card" onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="email-input"
                            aria-label="Email Address"
                        />
                        <button
                            type="submit"
                            className="check-button"
                            disabled={loading}
                            aria-label="Check Email for Data Leaks"
                        >
                            {loading ? "Checking..." : "Check Now"}
                        </button>
                    </div>
                </form>

                {/* Error Message */}
                {errorMessage && <p className="error-message">{errorMessage}</p>}

                {/* Analysis Results */}
                {breaches.length > 0 && (
                    <div className="analysis-results card" id="analysis-results">
                        <h2><FontAwesomeIcon icon={faInfoCircle} className="section-icon" /> Analysis Results for {email}:</h2>
                        
                        {/* Breach Results */}
                        <div className="breach-results">
                            <h3>Data Breaches:</h3>
                            <ul className="breach-list">
                                {breachesToShow.map((breach) => (
                                    <li key={breach.Name} className="breach-item">
                                        <div className="breach-summary">
                                            <h4>{breach.Title}</h4>
                                            <p><strong>Breach Date:</strong> {new Date(breach.BreachDate).toLocaleDateString()}</p>
                                            <p><strong>Leaked Data:</strong> {breach.DataClasses.join(", ")}</p>
                                            <button
                                                className="show-more-btn"
                                                onClick={() => toggleDetails(breach)}
                                                aria-label={expandedBreach === breach ? "Hide Details" : "Show Details"}
                                            >
                                                <FontAwesomeIcon icon={expandedBreach === breach ? faChevronUp : faChevronDown} />
                                            </button>
                                        </div>
                                        {expandedBreach === breach && (
                                            <div className="breach-details">
                                                <p dangerouslySetInnerHTML={{ __html: breach.Description }} />
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            {/* Show More Button */}
                            {breaches.length > 4 && !showMore && (
                                <div className="show-more">
                                    <button onClick={() => setShowMore(true)} aria-label="Show More Breaches">
                                        Show {breaches.length - 4} More Breaches <FontAwesomeIcon icon={faChevronDown} />
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Privacy Statement */}
                        <p className="privacy-statement">
                            Your privacy is our priority. All entries are completely secure, never stored, and never shared. Guaranteed.
                        </p>

                        {/* Featured In Section */}
                        <div className="featured-in-section">
                            <h3>Featured In:</h3>
                            <p>Our tool has been highlighted in renowned publications such as <strong>The New York Times</strong> and <strong>NerdWallet</strong>.</p>
                        </div>
                    </div>
                )}

                {/* Data Brokers Check Section */}
                {breaches.length > 0 && (
                    <div className="data-brokers-check card">
                        <h2>Check If Your Information Is Sold by Data Brokers</h2>
                        <p>Enter your first and last name below to check if your data is being sold by data brokers:</p>
                        <form className="name-form" onSubmit={handleNameSubmit}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    className="name-input"
                                    aria-label="First Name"
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    className="name-input"
                                    aria-label="Last Name"
                                />
                                <button type="submit" className="check-info-button">Check Brokers</button>
                            </div>
                        </form>
                        {infoCheckResult && (
                            <div className={`info-check-result ${infoCheckResult.success ? 'success' : 'error'}`}>
                                {infoCheckResult.message}
                            </div>
                        )}
                    </div>
                )}

                {/* Breach Statistics */}
                <div className="breach-stats card">
                    <div className="breach-stat">
                        <h2>14.1 Billion</h2>
                        <p>Total Accounts Leaked</p>
                    </div>
                    <div className="breach-stat">
                        <h2>808</h2>
                        <p>Leaked Websites</p>
                    </div>
                    <div className="breach-stat">
                        <h2>115,796</h2>
                        <p>Pastes</p>
                    </div>
                    <div className="breach-stat">
                        <h2>228 Million</h2>
                        <p>Paste Accounts</p>
                    </div>
                </div>

                {/* Additional Information */}
                <div className="additional-info card">
                    <h2>What to Do Next?</h2>
                    <p>
                        If your email has been exposed in a data breach, take immediate action:
                    </p>
                    <ul className="additional-tips-list">
                        <li><strong>Change Your Passwords:</strong> Update passwords for all affected accounts, ensuring they are strong and unique.</li>
                        <li><strong>Enable Two-Factor Authentication (2FA):</strong> Add an extra layer of security to your accounts.</li>
                        <li><strong>Monitor Your Accounts:</strong> Keep an eye on your financial and personal accounts for any suspicious activity.</li>
                        <li><strong>Use a Password Manager:</strong> Securely store and manage your passwords with a trusted password manager.</li>
                        <li><strong>Stay Informed:</strong> Subscribe to security alerts to stay updated on the latest threats and breaches.</li>
                    </ul>
                </div>

                {/* Frequently Asked Questions (FAQ) */}
                <div className="faq-section card">
                    <h2>Frequently Asked Questions</h2>
                    <div className="faq-item">
                        <h3>What is a data breach?</h3>
                        <p>
                            A data breach occurs when unauthorized individuals access sensitive, protected, or confidential data, often resulting in the exposure of personal information.
                        </p>
                    </div>
                    <div className="faq-item">
                        <h3>How can I tell if my email was compromised?</h3>
                        <p>
                            By using our Email Leak Checker, you can input your email address to see if it has been involved in any known data breaches.
                        </p>
                    </div>
                    <div className="faq-item">
                        <h3>What should I do if my email is found in a breach?</h3>
                        <p>
                            If your email appears in a breach, immediately change your passwords for affected accounts, enable two-factor authentication, and monitor your accounts for any unusual activity.
                        </p>
                    </div>
                    <div className="faq-item">
                        <h3>Are your services secure?</h3>
                        <p>
                            Yes, we prioritize your privacy and security. All data entered is encrypted, never stored, and never shared with third parties.
                        </p>
                    </div>
                    <div className="faq-item">
                        <h3>Can I check multiple emails?</h3>
                        <p>
                            Currently, our tool supports checking one email address at a time to ensure accurate and secure results.
                        </p>
                    </div>
                </div>

                {/* Footer */}
                <Footer />
            </div>
        </div>
    );
};

export default DataLeakPage;
