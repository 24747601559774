import React, { useState } from 'react';
import { FaEraser } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import './MobileNavbar.css';

const MobileNavbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        if (isDropdownOpen) setDropdownOpen(false); // Close dropdown when menu is closed
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    return (
        <React.Fragment>
            {/* Navbar Header */}
            <header className="navbar-header">
                <div className="navbar-content">
                    <div className="logo-section">
                        <FaEraser className="logo-icon" />
                        <span className="navbar-title">CleanData</span>
                    </div>
                    <button onClick={toggleMenu} className="menu-button">
                        {isMenuOpen ? <FiX className="menu-icon" /> : <FiMenu className="menu-icon" />}
                    </button>
                </div>
            </header>

            {/* Slide-In Menu */}
            <nav className={`slide-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul className="menu-list">
                    <li className="menu-item">
                        <a href="http://www.Cleandata.me" onClick={toggleMenu}>Home</a>
                    </li>
                    <li className="menu-item">
                        <a href="http://www.Cleandata.me/pricing" onClick={toggleMenu}>Pricing</a>
                    </li>
                    <li className="menu-item">
                        <a href="http://www.Cleandata.me/blog" onClick={toggleMenu}>Blog</a>
                    </li>
                    <li className="menu-item">
                        <a href="http://www.Cleandata.me/contact" onClick={toggleMenu}>Contact</a>
                    </li>

                    {/* Free Tools Button with Dropdown */}
                    <li className="menu-item">
                        <button className="free-tools-button" onClick={toggleDropdown}>
                            Free Tools
                        </button>
                        {isDropdownOpen && (
                            <ul className="dropdown-menu">
                                <li><a href="https://app.cleandata.me/password-check" onClick={toggleMenu}>Password Checker</a></li>
                                <li><a href="https://app.cleandata.me/file-scan" onClick={toggleMenu}>Virus Scanner</a></li>
                                <li><a href="https://app.cleandata.me/location" onClick={toggleMenu}>Data Broker Check</a></li>
                                <li><a href="https://app.cleandata.me/data-leak" onClick={toggleMenu}>Check Your Email</a></li>
                            </ul>
                        )}
                    </li>

                    <li className="menu-item sign-in">
                        <a href="https://app.cleandata.me/login" onClick={toggleMenu}>Sign In</a>
                    </li>
                </ul>
            </nav>
        </React.Fragment>
    );
};

export default MobileNavbar;
