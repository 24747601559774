import React, { useState, useEffect, useContext } from 'react';
import './FeatureToggles.css';
import ToggleSwitch from './ToggleSwitch';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';

const FeatureToggles = () => {
    const [toggles, setToggles] = useState({
        multiFactorAuth: false,
        roleBasedAccess: false,
        monitoringVerification: false,
        dataLeakNotification: false,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updatingToggle, setUpdatingToggle] = useState(null);
    const { getToken } = useContext(AuthContext);

    const fetchToggles = async () => {
        try {
            const token = await getToken();
            const response = await axios.get('https://dashboard-api-server.fly.dev/api/feature-toggles', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setToggles(response.data);
            setError(null);
        } catch (err) {
            console.error('Error fetching toggles:', err);
            setError(`Failed to fetch toggles: ${err.response?.data?.error || err.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchToggles();
    }, [getToken]);

    const handleToggleChange = async (name) => {
        setUpdatingToggle(name);
        const originalState = toggles[name];
        try {
            // Optimistically update the UI
            setToggles(prevToggles => ({...prevToggles, [name]: !prevToggles[name]}));

            const token = await getToken();
            const response = await axios.put('https://dashboard-api-server.fly.dev/api/feature-toggles', 
                { toggleName: name, isEnabled: !originalState },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setToggles(response.data);
            setError(null);
        } catch (err) {
            console.error(`Failed to update toggle: ${err.message}`);
            setError(`Failed to update ${name}: ${err.response?.data?.error || err.message}`);
            // Revert the toggle state in case of error
            setToggles(prevToggles => ({...prevToggles, [name]: originalState}));
        } finally {
            setUpdatingToggle(null);
        }
    };

    if (loading) {
        return <div className="loading-message">Loading feature toggles...</div>;
    }

    return (
        <div className="feature-toggles">
            {error && <div className="error-message">{error}</div>}

            {Object.entries(toggles).map(([key, value]) => (
                <div className="toggle-item" key={key}>
                    <div className="toggle-header">
                        <span className="toggle-title">{formatToggleName(key)}</span>
                        <div className="toggle-switch-container">
                            <ToggleSwitch
                                id={key}
                                isOn={value}
                                handleToggle={() => handleToggleChange(key)}
                                disabled={updatingToggle === key}
                            />
                        </div>
                    </div>
                    <p className="toggle-description">{getToggleDescription(key)}</p>
                </div>
            ))}
        </div>
    );
};

const formatToggleName = (key) => {
    return key.split(/(?=[A-Z])/).join(' ');
};

const getToggleDescription = (key) => {
    const descriptions = {
        multiFactorAuth: "Enable extra security with multi-factor authentication.",
        roleBasedAccess: "Manage permissions based on user roles.",
        monitoringVerification: "Enable 24/7 comprehensive monitoring.",
        dataLeakNotification: "Get notified if your data is leaked.",
    };
    return descriptions[key] || "No description available.";
};

export default FeatureToggles;