// App.js

import React from 'react';
import { Route, Routes, useLocation, Link } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Dashboard from './pages/Dashboard';
import PaymentPage from './pages/PaymentPage';
import EditInfoPage from './pages/EditInfoPage';
import SuccessPage from './pages/SuccessPage';
import DataRemovalsPage from './pages/DataRemovalsPage';
import SupportPage from './pages/SupportPage';
import LocationPage from './pages/LocationPage';
import ScanningPage from './pages/ScanningPage';
import TrialSignupPage from './pages/TrialSignupPage';
import DataLeakPage from './pages/DataLeakPage';
import PasswordCheckPage from './pages/PasswordCheckPage';
import FileScanPage from './pages/FileScanPage';
import ChatPage from './pages/ChatPage';
import ResultsPage from './pages/ResultsPage';

import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import Sidebar from './components/Sidebar';
import useWindowSize from './hooks/useWindowSize';
import ScrollToTop from './ScrollToTop'; // Import the ScrollToTop component
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
    const { width } = useWindowSize();
    const location = useLocation();

    // Define pages where the sidebar should be hidden
    const hideSidebarPages = [
        '/location',
        '/scanning',
        '/signup',
        '/data-leak',
        '/password-check',
        '/file-scan',
        '/support',
        '/login',
        '/scamai',
        '/results'
    ];

    const shouldHideSidebar = hideSidebarPages.includes(location.pathname);

    return (
        <HelmetProvider>
            <div className={`app-container ${shouldHideSidebar ? 'full-screen' : ''}`}>
                {/* Render Sidebar for larger screens and not on hideSidebarPages */}
                {!shouldHideSidebar && (width > 768 ? <Sidebar /> : <MobileNavbar />)}
                
                <div className={`main-content ${shouldHideSidebar ? 'full-screen' : ''}`}>
                    {/* Render Navbar only on larger screens and not on hideSidebarPages */}
                    {width > 768 && !shouldHideSidebar && <Navbar />}

                    {/* Include the ScrollToTop component */}
                    <ScrollToTop />

                    <Routes>
                        {/* Home Page */}
                        <Route
                            path="/"
                            element={
                                <>
                                    <Helmet>
                                        <title>Protect Your Data - Home | Secure Your Digital Presence</title>
                                        <meta
                                            name="description"
                                            content="Protect your online privacy with data removal services. Secure your digital identity today."
                                        />
                                    </Helmet>
                                    <Home />
                                </>
                            }
                        />
                        
                        {/* Login Page */}
                        <Route
                            path="/login"
                            element={
                                <>
                                    <Helmet>
                                        <title>Login - Protect Your Data | Access Your Privacy Dashboard</title>
                                        <meta
                                            name="description"
                                            content="Log in to your Protect Your Data account to manage your online privacy and data removal requests."
                                        />
                                    </Helmet>
                                    <LoginPage />
                                </>
                            }
                        />
                        
                        {/* Signup Page */}
                        <Route
                            path="/signup"
                            element={
                                <>
                                    <Helmet>
                                        <title>Sign Up - Protect Your Data | Get Started with Data Protection</title>
                                        <meta
                                            name="description"
                                            content="Create an account to secure your personal information and protect your online identity with data removal services."
                                        />
                                    </Helmet>
                                    <SignupPage />
                                </>
                            }
                        />
                        
                        {/* Dashboard Page */}
                        <Route
                            path="/dashboard"
                            element={
                                <>
                                    <Helmet>
                                        <title>Dashboard - Protect Your Data | Manage Your Privacy Settings</title>
                                        <meta
                                            name="description"
                                            content="Access your Protect Your Data dashboard to monitor and manage your privacy and data removal services."
                                        />
                                    </Helmet>
                                    <Dashboard />
                                </>
                            }
                        />
                        
                        {/* Edit Information Page */}
                        <Route
                            path="/edit-info"
                            element={
                                <>
                                    <Helmet>
                                        <title>Edit Information - Protect Your Data | Update Your Personal Details</title>
                                        <meta
                                            name="description"
                                            content="Edit your personal information to ensure accurate data removal from online platforms."
                                        />
                                    </Helmet>
                                    <EditInfoPage />
                                </>
                            }
                        />
                        
                        {/* Success Page */}
                        <Route
                            path="/success"
                            element={
                                <>
                                    <Helmet>
                                        <title>Success - Protect Your Data | Data Removal Complete</title>
                                        <meta
                                            name="description"
                                            content="Your data removal request has been successfully completed. Your online privacy is now protected."
                                        />
                                    </Helmet>
                                    <SuccessPage />
                                </>
                            }
                        />
                        
                        {/* Data Removals Page */}
                        <Route
                            path="/data-removals"
                            element={
                                <>
                                    <Helmet>
                                        <title>Data Removals - Protect Your Data | Track Your Privacy Requests</title>
                                        <meta
                                            name="description"
                                            content="Monitor and track your data removal requests. Take control of your online privacy with Protect Your Data."
                                        />
                                    </Helmet>
                                    <DataRemovalsPage />
                                </>
                            }
                        />
                        
                        {/* Support Page */}
                        <Route
                            path="/support"
                            element={
                                <>
                                    <Helmet>
                                        <title>Support - Protect Your Data | Get Help with Data Protection</title>
                                        <meta
                                            name="description"
                                            content="Need help with data removal or account management? Contact Protect Your Data support for assistance."
                                        />
                                    </Helmet>
                                    <SupportPage />
                                </>
                            }
                        />
                        
                        {/* Location Page */}
                        <Route
                            path="/location"
                            element={
                                <>
                                    <Helmet>
                                        <title>Location - Protect Your Data | Optimize Data Removal for Your Region</title>
                                        <meta
                                            name="description"
                                            content="Provide your location to tailor data removal services to your specific region and ensure privacy protection."
                                        />
                                    </Helmet>
                                    <LocationPage />
                                </>
                            }
                        />
                        
                        {/* Scanning Page */}
                        <Route
                            path="/scanning"
                            element={
                                <>
                                    <Helmet>
                                        <title>Scanning - Protect Your Data | Searching for Your Personal Information</title>
                                        <meta
                                            name="description"
                                            content="We are scanning online data brokers to identify and remove your personal information for enhanced privacy."
                                        />
                                    </Helmet>
                                    <ScanningPage />
                                </>
                            }
                        />
                        
                        {/* Results Page */}
                        <Route
                            path="/results"
                            element={
                                <>
                                    <Helmet>
                                        <title>Scan Results - Protect Your Data | Potential Threats Found</title>
                                        <meta
                                            name="description"
                                            content="View the results of your data scan and see where your personal information is exposed."
                                        />
                                    </Helmet>
                                    <ResultsPage />
                                </>
                            }
                        />
                        
                        {/* Trial Signup Page */}
                        <Route
                            path="/trial-signup"
                            element={
                                <>
                                    <Helmet>
                                        <title>Free Trial Signup - Protect Your Data | Start Protecting Your Privacy Now</title>
                                        <meta
                                            name="description"
                                            content="Sign up for a free trial to get started with our data protection and privacy services. Remove your online data today."
                                        />
                                    </Helmet>
                                    <TrialSignupPage />
                                </>
                            }
                        />
                        
                        {/* Data Leak Check Page */}
                        <Route
                            path="/data-leak"
                            element={
                                <>
                                    <Helmet>
                                        <title>Data Leak Check - Protect Your Data | Find if Your Data Was Leaked</title>
                                        <meta
                                            name="description"
                                            content="Check if your email was compromised in data breaches using our Have I Been Pwned integration. Secure your privacy today."
                                        />
                                    </Helmet>
                                    <DataLeakPage />
                                </>
                            }
                        />
                        
                        {/* Password Strength Checker Page */}
                        <Route
                            path="/password-check"
                            element={
                                <>
                                    <Helmet>
                                        <title>Password Strength Checker - Protect Your Data | Secure Your Passwords</title>
                                        <meta
                                            name="description"
                                            content="Evaluate the strength of your passwords, receive suggestions for improvement, and generate secure passwords to enhance your online security."
                                        />
                                    </Helmet>
                                    <PasswordCheckPage />
                                </>
                            }
                        />
                        
                        {/* File Scan Page */}
                        <Route
                            path="/file-scan"
                            element={
                                <>
                                    <Helmet>
                                        <title>File Scan - Protect Your Data | Scan Your Files for Privacy</title>
                                        <meta
                                            name="description"
                                            content="Perform a file scan to identify and protect sensitive information within your documents. Enhance your privacy with our scanning services."
                                        />
                                    </Helmet>
                                    <FileScanPage />
                                </>
                            }
                        />
                        
                        {/* Scam AI Chat Page */}
                        <Route
                            path="/scamai"
                            element={
                                <>
                                    <Helmet>
                                        <title>Scam AI - Protect Your Data | Prevent Scams</title>
                                        <meta
                                            name="description"
                                            content="Chat with our AI to detect potential scams and protect yourself from fraud."
                                        />
                                    </Helmet>
                                    <ChatPage />
                                </>
                            }
                        />
                        
                        {/* Fallback Route for Undefined Paths */}
                        <Route
                            path="*"
                            element={
                                <>
                                    <Helmet>
                                        <title>404 - Page Not Found | Protect Your Data</title>
                                        <meta
                                            name="description"
                                            content="The page you are looking for does not exist. Navigate back to the homepage."
                                        />
                                    </Helmet>
                                    <div className="not-found-page" style={styles.notFoundPage}>
                                        <h1>404 - Page Not Found</h1>
                                        <p>Sorry, the page you are looking for does not exist.</p>
                                        <Link to="/" className="home-link" style={styles.homeLink}>Go Back Home</Link>
                                    </div>
                                </>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </HelmetProvider>
    );
}

const styles = {
    notFoundPage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#1e1e1e',
        padding: '20px',
    },
    homeLink: {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#00ff85',
        color: '#000',
        textDecoration: 'none',
        borderRadius: '5px',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease',
    }
};

export default App;
