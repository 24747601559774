// src/components/Sidebar.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faUserEdit,
    faExchangeAlt,
    faListAlt,
    faLifeRing,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import profileImage from '../assets/profile.jpg'; // Ensure this path is correct
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [userName, setUserName] = useState({ firstName: 'John', lastName: 'Wick' });
    const location = useLocation();

    useEffect(() => {
        const fetchUserName = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                console.warn('No token found, using default user name');
                return;
            }

            try {
                const response = await fetch('https://cleandata.fly.dev/api/user-data', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    console.error('Failed to fetch user data, status:', response.status);
                    console.error('Response text:', await response.text());
                    throw new Error('Failed to fetch user data');
                }

                const user = await response.json();
                setUserName({
                    firstName: user.first_name || 'John',
                    lastName: user.last_name || 'Wick',
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserName();
    }, []);

    return (
        <div className="sidebar">
            <div className="sidebar-profile">
                <img src={profileImage} alt="User" className="profile-pic" />
                <div className="profile-info">
                    <h3>{`${userName.firstName} ${userName.lastName}`}</h3>
                    <p>1 Year Plan</p>
                </div>
            </div>
            <nav className="sidebar-nav">
                <Link to="/dashboard" className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" /> Dashboard
                </Link>
                <Link to="/edit-info" className={`nav-item ${location.pathname === '/edit-info' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faUserEdit} className="nav-icon" /> Edit Your Info
                </Link>
                <Link to="/change-plan" className={`nav-item ${location.pathname === '/change-plan' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faExchangeAlt} className="nav-icon" /> Change Plan
                </Link>
                <Link to="/sites" className={`nav-item ${location.pathname === '/sites' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faListAlt} className="nav-icon" /> Sites We Cover
                </Link>
                <Link to="/support" className={`nav-item ${location.pathname === '/support' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faLifeRing} className="nav-icon" /> Support
                </Link>
            </nav>
            <button className="add-family-member-button">
                <FontAwesomeIcon icon={faPlus} className="nav-icon" /> Add a Family Member
            </button>
        </div>
    );
};

export default Sidebar;
