import React from 'react';
import PropTypes from 'prop-types';
import './DataBrokerListComponent.css';
import { FaSpinner } from 'react-icons/fa';

const DataBrokerListComponent = ({ dataBrokerList = [], loading }) => {
    if (loading) {
        return (
            <div className="loading-state">
                <FaSpinner className="spinner" /> Loading Data Brokers...
            </div>
        );
    }

    if (!Array.isArray(dataBrokerList)) {
        return <div className="error-message">Invalid Data Brokers Data.</div>;
    }

    return (
        <div className="databrokerlist-container">
            <table className="databrokerlist-table">
                <thead>
                    <tr>
                        <th>Profile</th>
                        <th>Status</th>
                        <th>Removal Time</th>
                        <th>Data Broker Report</th>
                    </tr>
                </thead>
                <tbody>
                    {dataBrokerList.length === 0 ? (
                        <tr>
                            <td colSpan="4" className="no-data">
                                No Data Brokers Found.
                            </td>
                        </tr>
                    ) : (
                        dataBrokerList.map((broker, index) => (
                            <tr key={index}>
                                <td data-label="Profile">{broker.broker_name || 'N/A'}</td>
                                <td data-label="Status">{calculateStatus(broker.removalTime)}</td>
                                <td data-label="Removal Time">{broker.removalTime || randomRemovalTime()}</td>
                                <td data-label="Data Broker Report">
                                    <a href={broker.dataBrokerReport || '#'} target="_blank" rel="noopener noreferrer">
                                        {calculateStatus(broker.removalTime) === 'Processing' ? 'Processing' : 'View Report'}
                                    </a>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

// Helper functions
const randomRemovalTime = () => `${Math.floor(Math.random() * 3) + 3} days`;

const calculateStatus = (removalTime) => {
    // Safeguard against undefined or invalid removalTime
    if (!removalTime || typeof removalTime !== 'string' || !removalTime.includes(' ')) {
        removalTime = randomRemovalTime();
    }

    const [value, unit] = removalTime.split(' ');
    const days = unit.includes('day') ? parseInt(value, 10) : 0;
    const removalDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
    return removalDate < new Date() ? 'Completed' : 'Processing';
};

DataBrokerListComponent.propTypes = {
    dataBrokerList: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};

export default DataBrokerListComponent;
