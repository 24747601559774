import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './EditInfoPage.css';
import ProgressTimeline from '../components/ProgressTimeline';
import FAQ2Component from '../components/FAQ2Component';
import CollapsibleSection from '../components/CollapsibleSection'; // Ensure this component is created
import { FaUser, FaEnvelope, FaPhone, FaHome, FaGlobe, FaCity, FaMapPin, FaLock } from 'react-icons/fa'; // Example icons

const countriesWithStates = [
    'United States',
    'Canada',
    'Australia',
    'Germany',
    // Add other countries that have states/provinces
];

const countries = [
    'United States', 'Canada', 'United Kingdom', 'Australia', 'Germany', 'France',
    'Brazil', 'India', 'China', 'Japan', 'South Korea', 'Mexico', 'Italy', 'Spain',
    'Russia', 'South Africa', 'New Zealand', 'Singapore', 'Malaysia', 'Thailand',
    'Vietnam', 'Philippines', 'Indonesia', 'Saudi Arabia', 'United Arab Emirates',
    'Turkey', 'Egypt', 'Morocco', 'Kenya', 'Nigeria', 'Ghana'
];

const EditInfoPage = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        country: '',
        state: '', // New field for state
        city: '',
        zipCode: '',
        additionalEmails: [''],
        additionalPhoneNumbers: [''],
    });
    const [options, setOptions] = useState({
        states: [],
        cities: []
    });
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [formStatus, setFormStatus] = useState('unsubmitted');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            try {
                const response = await fetch('https://cleandata.fly.dev/api/user-data', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                if (!response.ok) throw new Error('Failed to fetch user data');

                const user = await response.json();
                setFormData((prevData) => ({
                    ...prevData,
                    email: user.email || '',
                    firstName: user.first_name || '',
                    lastName: user.last_name || '',
                    phoneNumber: user.phone_number || '',
                    address: user.address || '',
                    country: user.country || '',
                    state: user.state || '', // Assuming user data includes state
                    city: user.city || '',
                    zipCode: user.zip_code || '',
                    additionalEmails: user.additional_emails || [''],
                    additionalPhoneNumbers: user.additional_phone_numbers || [''],
                }));
            } catch (error) {
                console.error('Error fetching user data:', error);
                navigate('/login');
            }
        };

        fetchUserData();
    }, [navigate]);

    useEffect(() => {
        if (formData.country) {
            handleCountryChange({ target: { value: formData.country } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validate = () => {
        const newErrors = {};

        if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
        if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (formData.phoneNumber && !/^\+?[1-9]\d{1,14}$/.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Phone number is invalid';
        }
        if (!formData.address.trim()) newErrors.address = 'Address is required';
        if (!formData.country) newErrors.country = 'Country is required';
        
        if (countriesWithStates.includes(formData.country)) {
            if (!formData.state) newErrors.state = 'State is required';
        } else {
            if (!formData.city) newErrors.city = 'City is required';
        }
        
        if (!formData.zipCode.trim()) newErrors.zipCode = 'Zip code is required';
        // Validate additional emails
        formData.additionalEmails.forEach((email, index) => {
            if (email && !/\S+@\S+\.\S+/.test(email)) {
                newErrors[`additionalEmails_${index}`] = 'Invalid email';
            }
        });
        // Validate additional phone numbers
        formData.additionalPhoneNumbers.forEach((phone, index) => {
            if (phone && !/^\+?[1-9]\d{1,14}$/.test(phone)) {
                newErrors[`additionalPhoneNumbers_${index}`] = 'Invalid phone number';
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        // Live validation
        setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    };

    const handleCountryChange = async (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            country: value,
            state: '',
            city: ''
        }));
        setOptions({ states: [], cities: [] });
        setLoadingOptions(true);

        try {
            if (countriesWithStates.includes(value)) {
                // Fetch states
                const response = await fetch('https://countriesnow.space/api/v0.1/countries/states', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ country: value }),
                });

                if (!response.ok) throw new Error('Error fetching states');

                const result = await response.json();
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    states: result.data.states.map(state => state.name)
                }));
            } else {
                // Fetch cities
                const response = await fetch('https://countriesnow.space/api/v0.1/countries/cities', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ country: value }),
                });

                if (!response.ok) throw new Error('Error fetching cities');

                const result = await response.json();
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    cities: result.data
                }));
            }
        } catch (error) {
            console.error('Error fetching options:', error);
            setOptions({ states: [], cities: [] });
        } finally {
            setLoadingOptions(false);
        }
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemoveField = (field, index) => {
        setFormData((prevData) => {
            const updatedFields = [...prevData[field]];
            updatedFields.splice(index, 1);
            return { ...prevData, [field]: updatedFields };
        });
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[`${field}_${index}`];
            return updatedErrors;
        });
    };

    const handleAdditionalChange = (field, index, value) => {
        const updatedFields = [...formData[field]];
        updatedFields[index] = value;
        setFormData({ ...formData, [field]: updatedFields });

        // Live validation
        setErrors((prevErrors) => ({ ...prevErrors, [`${field}_${index}`]: undefined }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        setIsSubmitting(true);
        setFormStatus('unsubmitted');

        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://cleandata.fly.dev/api/submit-data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) throw new Error('Form submission error');

            setFormStatus('submitted');
            setTimeout(() => {
                navigate('/dashboard');
            }, 2000);
        } catch (error) {
            console.error('Form submission error:', error);
            setFormStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="location-page">
            <div className="location-container">
                <div className="header-card">
                    <h1 className="changing-text">Edit Your Information</h1>
                </div>
                <form onSubmit={handleSubmit} className="formStyle" aria-label="Edit Information Form">
                    {formStatus === 'submitted' && (
                        <div className="responseText">Form submitted successfully! Redirecting...</div>
                    )}
                    {formStatus === 'error' && (
                        <div className="responseTextError">Something went wrong. Please try again!</div>
                    )}

                    {/* Personal Information Section */}
                    <CollapsibleSection title="Personal Information" icon={<FaUser />}>
                        <div className="rowStyle">
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className={`location-input ${errors.firstName ? 'inputError' : ''}`}
                                    aria-label="First Name"
                                    aria-invalid={!!errors.firstName}
                                    aria-describedby="firstNameError"
                                />
                                {errors.firstName && (
                                    <span className="errorText" id="firstNameError">
                                        {errors.firstName}
                                    </span>
                                )}
                            </div>
                            <div className="input-wrapper">
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className={`location-input ${errors.lastName ? 'inputError' : ''}`}
                                    aria-label="Last Name"
                                    aria-invalid={!!errors.lastName}
                                    aria-describedby="lastNameError"
                                />
                                {errors.lastName && (
                                    <span className="errorText" id="lastNameError">
                                        {errors.lastName}
                                    </span>
                                )}
                            </div>
                        </div>
                    </CollapsibleSection>

                    {/* Contact Details Section */}
                    <CollapsibleSection title="Contact Details" icon={<FaEnvelope />}>
                        <div className="input-wrapper">
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                className={`location-input ${errors.email ? 'inputError' : ''}`}
                                aria-label="Email"
                                aria-invalid={!!errors.email}
                                aria-describedby="emailError"
                            />
                            {errors.email && (
                                <span className="errorText" id="emailError">
                                    {errors.email}
                                </span>
                            )}
                        </div>
                        <div className="input-wrapper">
                            <input
                                type="tel"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className={`location-input ${errors.phoneNumber ? 'inputError' : ''}`}
                                aria-label="Phone Number"
                                aria-invalid={!!errors.phoneNumber}
                                aria-describedby="phoneNumberError"
                            />
                            {errors.phoneNumber && (
                                <span className="errorText" id="phoneNumberError">
                                    {errors.phoneNumber}
                                </span>
                            )}
                        </div>
                    </CollapsibleSection>

                    {/* Address Details Section */}
                    <CollapsibleSection title="Address Details" icon={<FaHome />}>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                name="address"
                                placeholder="Address"
                                value={formData.address}
                                onChange={handleChange}
                                className={`location-input ${errors.address ? 'inputError' : ''}`}
                                aria-label="Address"
                                aria-invalid={!!errors.address}
                                aria-describedby="addressError"
                                // Implement Google Places Autocomplete here if desired
                            />
                            {errors.address && (
                                <span className="errorText" id="addressError">
                                    {errors.address}
                                </span>
                            )}
                        </div>
                        <div className="rowStyle">
                            <div className="input-wrapper">
                                <select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleCountryChange}
                                    className={`location-input ${errors.country ? 'inputError' : ''}`}
                                    aria-label="Country"
                                    aria-invalid={!!errors.country}
                                    aria-describedby="countryError"
                                >
                                    <option value="">Select Country...</option>
                                    {countries.map((country, index) => (
                                        <option key={index} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                                {errors.country && (
                                    <span className="errorText" id="countryError">
                                        {errors.country}
                                    </span>
                                )}
                            </div>
                            {loadingOptions ? (
                                <div className="input-wrapper">
                                    <select
                                        name="placeholder"
                                        disabled
                                        className="location-input"
                                        aria-label="Loading..."
                                    >
                                        <option>Loading...</option>
                                    </select>
                                </div>
                            ) : countriesWithStates.includes(formData.country) ? (
                                <div className="input-wrapper">
                                    <select
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                        className={`location-input ${errors.state ? 'inputError' : ''}`}
                                        aria-label="State"
                                        aria-invalid={!!errors.state}
                                        aria-describedby="stateError"
                                    >
                                        <option value="">Select State...</option>
                                        {options.states.map((state, index) => (
                                            <option key={index} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.state && (
                                        <span className="errorText" id="stateError">
                                            {errors.state}
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <div className="input-wrapper">
                                    <select
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className={`location-input ${errors.city ? 'inputError' : ''}`}
                                        disabled={loadingOptions}
                                        aria-label="City"
                                        aria-invalid={!!errors.city}
                                        aria-describedby="cityError"
                                    >
                                        <option value="">{options.cities.length > 0 ? 'Select City...' : 'No cities available'}</option>
                                        {options.cities.map((city, index) => (
                                            <option key={index} value={city}>
                                                {city}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.city && (
                                        <span className="errorText" id="cityError">
                                            {errors.city}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                name="zipCode"
                                placeholder="Zip Code"
                                value={formData.zipCode}
                                onChange={handleChange}
                                className={`location-input ${errors.zipCode ? 'inputError' : ''}`}
                                aria-label="Zip Code"
                                aria-invalid={!!errors.zipCode}
                                aria-describedby="zipCodeError"
                            />
                            {errors.zipCode && (
                                <span className="errorText" id="zipCodeError">
                                    {errors.zipCode}
                                </span>
                            )}
                        </div>
                    </CollapsibleSection>

                    {/* Additional Emails */}
                    <CollapsibleSection title="Additional Emails" icon={<FaEnvelope />}>
                        {formData.additionalEmails.map((email, index) => (
                            <div key={index} className="rowStyle">
                                <div className="input-wrapper">
                                    <input
                                        type="email"
                                        placeholder={`Additional Email ${index + 1}`}
                                        value={email}
                                        onChange={(e) => handleAdditionalChange('additionalEmails', index, e.target.value)}
                                        className={`location-input ${errors[`additionalEmails_${index}`] ? 'inputError' : ''}`}
                                        aria-label={`Additional Email ${index + 1}`}
                                        aria-invalid={!!errors[`additionalEmails_${index}`]}
                                        aria-describedby={`additionalEmails_${index}_Error`}
                                    />
                                    {errors[`additionalEmails_${index}`] && (
                                        <span className="errorText" id={`additionalEmails_${index}_Error`}>
                                            {errors[`additionalEmails_${index}`]}
                                        </span>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveField('additionalEmails', index)}
                                    className="removeButtonStyle"
                                    aria-label={`Remove Additional Email ${index + 1}`}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => handleAddField('additionalEmails')}
                            className="addButtonStyle"
                            aria-label="Add Additional Email"
                        >
                            Add Email
                        </button>
                    </CollapsibleSection>

                    {/* Additional Phone Numbers */}
                    <CollapsibleSection title="Additional Phone Numbers" icon={<FaPhone />}>
                        {formData.additionalPhoneNumbers.map((phone, index) => (
                            <div key={index} className="rowStyle">
                                <div className="input-wrapper">
                                    <input
                                        type="tel"
                                        placeholder={`Additional Phone Number ${index + 1}`}
                                        value={phone}
                                        onChange={(e) => handleAdditionalChange('additionalPhoneNumbers', index, e.target.value)}
                                        className={`location-input ${errors[`additionalPhoneNumbers_${index}`] ? 'inputError' : ''}`}
                                        aria-label={`Additional Phone Number ${index + 1}`}
                                        aria-invalid={!!errors[`additionalPhoneNumbers_${index}`]}
                                        aria-describedby={`additionalPhoneNumbers_${index}_Error`}
                                    />
                                    {errors[`additionalPhoneNumbers_${index}`] && (
                                        <span className="errorText" id={`additionalPhoneNumbers_${index}_Error`}>
                                            {errors[`additionalPhoneNumbers_${index}`]}
                                        </span>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveField('additionalPhoneNumbers', index)}
                                    className="removeButtonStyle"
                                    aria-label={`Remove Additional Phone Number ${index + 1}`}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={() => handleAddField('additionalPhoneNumbers')}
                            className="addButtonStyle"
                            aria-label="Add Additional Phone Number"
                        >
                            Add Phone Number
                        </button>
                    </CollapsibleSection>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="location-button submitButtonStyle"
                        disabled={isSubmitting}
                        aria-label="Submit Form"
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </div>
            <FAQ2Component />
        </div>
    );
}

export default EditInfoPage;
