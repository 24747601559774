// src/pages/ScanningPage.jsx

import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ScanningPage.css';
import peopleSearchSites from './peopleSearchSites';
import { fetchMatchProbability } from '../fetchMatchProbability'; // Ensure correct path
import generateScreenshotUrls from '../utils/generateScreenshotUrls';
import {
    FaExclamationTriangle,
    FaSearch,
    FaShieldAlt,
    FaDatabase,
    FaInfoCircle,
    FaCheckCircle,
    FaBug,
} from 'react-icons/fa';
// Removed MobileNavBar import if not needed

const auth = '72382-cd';

const ScanningPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { firstName, lastName, city, country } = location.state || {};

    // State Variables
    const [scannedCount, setScannedCount] = useState(0);
    const [matchCount, setMatchCount] = useState(0);
    const [matchProbability, setMatchProbability] = useState(null);
    const [shuffledSites, setShuffledSites] = useState([]);
    const [screenshotUrls, setScreenshotUrls] = useState([]);
    const [currentScreenshot, setCurrentScreenshot] = useState(null);
    const [currentThreat, setCurrentThreat] = useState(null);
    const [progress, setProgress] = useState(0);
    const [scanStage, setScanStage] = useState('initializing');
    const [scanMessage, setScanMessage] = useState('Initializing scan...');
    const [error, setError] = useState('');
    const [foundThreats, setFoundThreats] = useState([]);

    // Refs to maintain persistent indices and state values
    const siteIndexRef = useRef(0);
    const screenshotIndexRef = useRef(0);
    const matchCountRef = useRef(matchCount);
    const foundThreatsRef = useRef(foundThreats);

    // Update refs whenever state changes
    useEffect(() => {
        matchCountRef.current = matchCount;
    }, [matchCount]);

    useEffect(() => {
        foundThreatsRef.current = foundThreats;
    }, [foundThreats]);

    // Function to shuffle array
    const shuffleArray = (array) => {
        return array
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
    };

    // Fetch and set the screenshot URLs and shuffled sites
    useEffect(() => {
        if (!firstName || !lastName || !city || !country) {
            setError('Missing required information. Please go back and provide your details.');
            return;
        }

        const urls = generateScreenshotUrls(firstName, lastName);
        console.log('Generated screenshot URLs:', urls); // Debugging
        setScreenshotUrls(urls);

        const uniqueSites = Array.from(new Set(peopleSearchSites));
        const shuffled = shuffleArray(uniqueSites);
        setShuffledSites(shuffled);
    }, [firstName, lastName, city, country]);

    // Initialize match probability
    useEffect(() => {
        if (!firstName || !lastName || !city || !country || shuffledSites.length === 0) return;

        const initializeMatchProbability = async () => {
            try {
                let probability = await fetchMatchProbability(firstName, lastName, city, country);
                // Remove minimum probability enforcement
                setMatchProbability(probability);
            } catch (err) {
                console.error('Error fetching match probability:', err);
                setError('Unable to fetch match probability at this time.');
                setMatchProbability(0); // Set default probability on error
            }
        };

        initializeMatchProbability();
    }, [firstName, lastName, city, country, shuffledSites]);

    // Log matchProbability whenever it updates
    useEffect(() => {
        if (matchProbability !== null) {
            console.log(`Realism Score: ${(matchProbability * 5).toFixed(2)}`); // Convert back to score
            const estimatedMatches = Math.ceil(matchProbability * shuffledSites.length);
            console.log(`Estimated Matches: ${estimatedMatches}`);
            const threatPercentage = (estimatedMatches / shuffledSites.length) * 100;
            console.log(`Threat Percentage: ${threatPercentage}%`);
        }
    }, [matchProbability, shuffledSites.length]);

    // Start scanning process
    useEffect(() => {
        if (
            matchProbability === null ||
            shuffledSites.length === 0 ||
            screenshotUrls.length === 0
        )
            return;

        const scanStages = [
            { threshold: 0.2, stage: 'initializing', message: 'Initializing scan...' },
            { threshold: 0.4, stage: 'searching', message: 'Searching data broker networks...' },
            { threshold: 0.6, stage: 'collecting', message: 'Collecting data...' },
            { threshold: 0.8, stage: 'analyzing', message: 'Analyzing potential matches...' },
            { threshold: 1.0, stage: 'finalizing', message: 'Finalizing scan results...' },
        ];

        const totalSites = shuffledSites.length;
        const totalMatches = Math.ceil(matchProbability * totalSites);
        const sitesToScanPerInterval = Math.ceil(totalSites / 24); // Scanning over 24 seconds, 1 scan per second

        const startTime = Date.now();

        // Function to cycle through screenshots
        const cycleScreenshots = () => {
            if (screenshotUrls.length > 0) {
                setCurrentScreenshot(screenshotUrls[screenshotIndexRef.current]);
                console.log('Current screenshot set to:', screenshotUrls[screenshotIndexRef.current]); // Debugging
                screenshotIndexRef.current = (screenshotIndexRef.current + 1) % screenshotUrls.length;
            } else {
                console.log('No screenshots to display.');
            }
        };

        // Start cycling screenshots immediately
        cycleScreenshots(); // Display the first screenshot without delay
        const screenshotInterval = setInterval(cycleScreenshots, 5000);

        // Function to scan sites
        const scanSites = () => {
            const currentScanned = siteIndexRef.current;
            const currentProgress = currentScanned / totalSites;
            setProgress(currentProgress * 100);

            const currentStage = scanStages.find((stage) => currentProgress < stage.threshold);
            if (currentStage) {
                if (scanStage !== currentStage.stage) { // Avoid unnecessary state updates
                    setScanStage(currentStage.stage);
                    setScanMessage(currentStage.message);
                }
            } else {
                setScanStage('complete');
                setScanMessage('Scan complete!');
                clearInterval(scanInterval);
                clearInterval(screenshotInterval);
                return;
            }

            // Simulate scanning sites
            for (let i = 0; i < sitesToScanPerInterval && siteIndexRef.current < totalSites; i++) {
                const remainingSites = totalSites - siteIndexRef.current;
                const remainingMatches = totalMatches - matchCountRef.current;
                const matchProbabilityForSite = remainingMatches / remainingSites;
                const isMatch = Math.random() < matchProbabilityForSite;
                if (isMatch && matchCountRef.current < totalMatches) {
                    const threatSite = shuffledSites[siteIndexRef.current];
                    setMatchCount((prev) => prev + 1);
                    setFoundThreats((prev) => [...prev, threatSite]);
                    setCurrentThreat(threatSite);
                    console.log(`Threat found: ${threatSite}`); // Debugging
                }
                siteIndexRef.current++;
            }
            setScannedCount(Math.min(siteIndexRef.current, totalSites));
        };

        // Start scanning interval every second
        const scanInterval = setInterval(scanSites, 1000);

        return () => {
            clearInterval(scanInterval);
            clearInterval(screenshotInterval);
        };
    }, [matchProbability, shuffledSites, screenshotUrls, navigate, scanStage]);

    // Separate useEffect to navigate when scanStage is 'complete'
    useEffect(() => {
        if (scanStage === 'complete') {
            console.log('Navigating to Results Page with:', {
                matchCount: matchCountRef.current,
                foundThreats: foundThreatsRef.current,
            }); // Debugging
            navigate('/results', {
                state: {
                    matchCount: matchCountRef.current,
                    foundThreats: foundThreatsRef.current,
                },
            });
        }
    }, [scanStage, navigate]);

    return (
        <div className="scanning-page">
            {/* Remove MobileNavBar if not needed */}
            {/* <MobileNavBar /> */}
            <div className="scanning-header">
                <h1>Scanning Data Brokers for:</h1>
                <h2>
                    {firstName} {lastName}
                </h2>
                <p>We're searching for your personal information on people-search sites.</p>
            </div>

            {error ? (
                <div className="error-message">
                    <p>{error}</p>
                </div>
            ) : (
                <div className="scanning-status">
                    <div
                        className={`scan-visualization ${
                            scanStage === 'complete' ? 'scan-complete' : ''
                        }`}
                    >
                        {/* Dynamic Icon Based on Scan Stage */}
                        {(() => {
                            switch (scanStage) {
                                case 'initializing':
                                    return <FaSearch className="scan-icon" />;
                                case 'searching':
                                    return <FaDatabase className="scan-icon" />;
                                case 'collecting':
                                    return <FaShieldAlt className="scan-icon" />;
                                case 'analyzing':
                                    return <FaBug className="scan-icon" />;
                                case 'finalizing':
                                    return <FaShieldAlt className="scan-icon" />;
                                case 'complete':
                                    return <FaCheckCircle className="scan-icon complete" />;
                                default:
                                    return <FaSearch className="scan-icon" />;
                            }
                        })()}
                        <div className="scan-message-container">
                            <p className="scan-message">{scanMessage}</p>
                        </div>
                    </div>
                    <p className="scanning-count">
                        {scannedCount} out of {shuffledSites.length} websites scanned
                    </p>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${progress}%` }}></div>
                    </div>
                    <div className="match-counter">
                        <p>Possible threats found: {matchCount}</p>
                    </div>
                    <div className="threats-list">
                        {foundThreats.map((threat, index) => (
                            <div key={index} className="threat-item">
                                <FaExclamationTriangle className="threat-icon" />
                                <span>{threat}</span>
                            </div>
                        ))}
                        {foundThreats.length === 0 && (
                            <p className="no-threats">No specific websites detected sharing your data.</p>
                        )}
                    </div>
                    <div className="screenshot-display">
                        {currentThreat && (
                            <p className="threat-message">Potential Threat Found: {currentThreat}</p>
                        )}
                        {currentScreenshot ? (
                            <img
                                src={`https://image.thum.io/get/auth/${auth}/noanimate/fullpage/maxAge/24/?url=${encodeURIComponent(
                                    currentScreenshot.url
                                )}&timestamp=${Date.now()}`} // Prevent caching
                                alt={`Screenshot from ${currentScreenshot.siteName}`}
                                className="screenshot"
                            />
                        ) : (
                            <div className="generic-scan-animation"></div>
                        )}
                        <div className="screenshot-overlay">
                            <div className="scan-overlay-animation"></div>
                        </div>
                    </div>
                </div>
            )}

            {!error && (
                <div className="info-box-bottom">
                    <FaInfoCircle className="info-icon-bottom" />
                    <p>
                        This is a quick free scan. With limited information, we cannot fully locate or
                        remove all your personal data from the internet. To take full control of your
                        data, sign up for a <strong>free trial</strong> and get your data removed.
                    </p>
                </div>
            )}
        </div>
    );

};

export default ScanningPage;
