// src/pages/ResultsPage.jsx

import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ResultsPage.css';
import { FaLock, FaStar } from 'react-icons/fa';
import trustBadge from '../assets/trust-badge.png'; // Ensure correct path

const ResultsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { matchCount = 0, foundThreats = [] } = location.state || {};

    // If no data is passed, redirect back to scanning or home
    useEffect(() => {
        if (!location.state) {
            navigate('/', { replace: true });
        } else {
            console.log('Received matchCount:', matchCount);
            console.log('Received foundThreats:', foundThreats);
        }
    }, [location.state, navigate, matchCount, foundThreats]);

    // Automatically redirect to external Pricing URL after 5 seconds
    useEffect(() => {
        const redirectTimeout = setTimeout(() => {
            window.location.href = 'https://cleandata.me/pricing';
        }, 5000); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(redirectTimeout);
    }, []);

    // Determine threat level based on matchCount
    const getThreatLevel = (count) => {
        if (count >= 51) return { level: 'High', color: '#e74c3c' }; // Red
        if (count >= 11) return { level: 'Medium', color: '#f1c40f' }; // Yellow
        return { level: 'Low', color: '#2ecc71' }; // Green
    };

    const threatLevel = getThreatLevel(matchCount);

    return (
        <div className="results-page">
            <div className="results-header">
                <h1>{matchCount} Threat{matchCount !== 1 ? 's' : ''} Detected</h1>
                <h2>Your data is compromised and available on the following websites:</h2>
            </div>

            {matchCount > 0 ? (
                <div className="results-status">
                    <div className="status-icon" style={{ backgroundColor: threatLevel.color }}>
                        <FaLock className="status-icon-inner" />
                    </div>
                    <div className="status-details">
                        <h3>{threatLevel.level} Threat Level</h3>
                        <p>Your personal information has been found on the following platforms:</p>
                    </div>
                </div>
            ) : (
                <div className="success-message">
                    <FaLock className="lock-icon-success" />
                    <p>Your data is secure. No threats were detected in our scan.</p>
                </div>
            )}

            {matchCount > 0 && (
                <div className="threats-report">
                    {foundThreats.length > 0 ? (
                        <ul className="threats-list">
                            {foundThreats.slice(0, 10).map((threat, index) => (
                                <li key={index} className="threat-item">
                                    <FaStar className="threat-icon" />
                                    {threat}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="no-threats">No specific websites detected sharing your data.</p>
                    )}
                    {foundThreats.length > 10 && (
                        <p className="more-sites">...and {foundThreats.length - 10} more sites.</p>
                    )}
                </div>
            )}

            <div className="importance-section">
                <h2>Why It Matters</h2>
                <p>Your exposed data can lead to severe consequences:</p>
                <ul className="importance-list">
                    <li><strong>Identity Theft:</strong> Criminals can impersonate you.</li>
                    <li><strong>Financial Fraud:</strong> Unauthorized transactions and debts.</li>
                    <li><strong>Privacy Violations:</strong> Harassment or stalking.</li>
                </ul>
                <p>Act now to protect yourself from these risks.</p>
            </div>

            {matchCount > 0 && (
                <div className="testimonial-section">
                    <h2>What Our Users Say</h2>
                    <div className="testimonial">
                        <FaStar className="star-icon" />
                        <p>
                            "CleanData helped me remove my information from over 50 sites. I feel much safer now knowing my data isn't out there for anyone to find."
                        </p>
                        <span>- Sarah L., Satisfied Customer</span>
                    </div>
                    <div className="testimonial">
                        <FaStar className="star-icon" />
                        <p>
                            "The process was quick and easy. I highly recommend their service to anyone concerned about their online privacy."
                        </p>
                        <span>- Mark R., Satisfied Customer</span>
                    </div>
                </div>
            )}

            <div className="cta-section">
                <h2>Secure Your Data Today</h2>
                <p>
                    Join thousands who have taken control of their personal information with our trusted service.
                </p>
                <button
                    className="cta-button"
                    onClick={() => window.location.href = 'https://cleandata.me/pricing'}
                >
                    Start Your Free Trial Now
                </button>
                <p className="guarantee">
                    <FaLock className="lock-icon" /> 100% Satisfaction Guarantee
                </p>
            </div>

            <div className="trust-section">
                <img src={trustBadge} alt="Trust Badge" className="trust-badge" loading="lazy" />
                <p>Your data is secure with us. We adhere to the highest industry standards.</p>
            </div>

            <p className="redirect-message">
                Redirecting you to secure your data...
            </p>
        </div>
    );

};

export default ResultsPage;
