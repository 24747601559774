import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { AuthContext } from '../context/AuthContext';
import './Dashboard.css';
import StatusBarComponent from '../components/StatusBarComponent';
import DashboardStats from '../components/DashboardStats';
import FeatureToggles from '../components/FeatureToggles';
import DashboardHeader from '../components/DashboardHeader';
import DataPointsComponent from '../components/DataPointsComponent';
import NavigationTabs from '../components/NavigationTabs';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { Footer } from '../components/Footer';
import { FaSpinner } from 'react-icons/fa';
import DataBrokerListComponent from '../components/DataBrokerListComponent'; // Ensure correct import

const Dashboard = () => {
    const navigate = useNavigate();
    const { user, authLoading, authError, getToken } = useContext(AuthContext);
    const [dashboardData, setDashboardData] = useState({
        stats: {
            dataBrokerExposure: 0,
            sitesScanned: 0,
            profilesFound: 0,
            isScanning: false, // Added isScanning
            lastScanTime: null, // Added lastScanTime
            nextScanTime: null, // Added nextScanTime
        },
        dataBrokerList: [],
        featureToggles: {
            multiFactorAuth: false,
            roleBasedAccess: false,
            monitoringVerification: false,
            dataLeakNotification: false,
        },
        brokerNames: [] // New state to hold broker names when matches are found
    });
    const [loading, setLoading] = useState({
        stats: true,
        dataBrokers: true,
        featureToggles: true
    });
    const [errors, setErrors] = useState({
        stats: null,
        dataBrokers: null,
        featureToggles: null
    });
    const [authToken, setAuthToken] = useState(null);

    useEffect(() => {
        const fetchAuthToken = async () => {
            try {
                const token = await getToken();
                setAuthToken(token);
            } catch (error) {
                console.error("Error fetching auth token:", error);
            }
        };
        fetchAuthToken();
    }, [getToken]);

    useEffect(() => {
        if (!authToken) return;

        const connectSocket = async () => {
            try {
                console.log("Token:", authToken); // Log the token for debugging

                const socket = io("https://dashboard-api-server.fly.dev", {
                    auth: { token: authToken },
                    transports: ['websocket', 'polling'],
                    reconnectionAttempts: 5,
                    timeout: 10000,
                    query: { token: authToken } // Add token to query params as well
                });

                socket.on('connect', () => {
                    console.log("Socket connected");
                    socket.emit('get-stats');
                    socket.emit('get-feature-toggles');
                });

                socket.on('connect_error', (error) => {
                    console.error('Connection error:', error);
                    setLoading(prev => ({
                        ...prev,
                        stats: false,
                        dataBrokers: false,
                        featureToggles: false
                    }));
                    setErrors(prev => ({
                        ...prev,
                        connection: 'Failed to connect to the server.'
                    }));
                });

                socket.on('error', (error) => {
                    console.error('Socket error:', error);
                    setLoading(prev => ({
                        ...prev,
                        stats: false,
                        dataBrokers: false,
                        featureToggles: false
                    }));
                    setErrors(prev => ({
                        ...prev,
                        socket: 'An error occurred with the socket connection.'
                    }));
                });

                socket.on('stats', (data) => {
                    console.log('Received stats:', data);
                    setDashboardData(prev => ({ 
                        ...prev, 
                        stats: { 
                            ...prev.stats, 
                            ...data.stats 
                        } 
                    }));
                    setLoading(prev => ({ ...prev, stats: false }));
                });

                socket.on('feature-toggles', (data) => {
                    console.log('Received feature-toggles:', data);
                    setDashboardData(prev => ({ ...prev, featureToggles: data.featureToggles }));
                    setLoading(prev => ({ ...prev, featureToggles: false }));
                });

                socket.on('data-brokers', (data) => {
                    console.log('Received data-brokers:', data);
                    setDashboardData(prev => ({ ...prev, dataBrokerList: data.dataBrokerList }));
                    setLoading(prev => ({ ...prev, dataBrokers: false }));
                });

                socket.on('status_update', (data) => {
                    console.log('Received status_update:', data);
                    setDashboardData(prev => ({
                        ...prev,
                        stats: { 
                            ...prev.stats, 
                            isScanning: data.stats.isScanning,
                            lastScanTime: data.stats.lastScanTime,
                            nextScanTime: data.stats.nextScanTime,
                            sitesScanned: data.stats.sitesScanned, // Ensure sitesScanned is updated
                        },
                        brokerNames: data.brokerNames || []
                    }));
                });

                socket.on('update', (data) => {
                    console.log('Received update:', data);
                    setDashboardData(prev => ({ ...prev, ...data }));
                });

                socket.on('realtime_update', (data) => {
                    console.log('Received realtime_update:', data);
                    // Handle real-time updates if necessary
                });

                socket.on('disconnect', (reason) => {
                    console.warn(`Socket disconnected: ${reason}`);
                    setLoading(prev => ({
                        ...prev,
                        stats: false,
                        dataBrokers: false,
                        featureToggles: false
                    }));
                    setErrors(prev => ({
                        ...prev,
                        disconnect: 'Disconnected from the server.'
                    }));
                });

                const connectionTimeout = setTimeout(() => {
                    if (!socket.connected) {
                        console.error('Connection timeout');
                        setLoading(prev => ({
                            ...prev,
                            stats: false,
                            dataBrokers: false,
                            featureToggles: false
                        }));
                        setErrors(prev => ({
                            ...prev,
                            timeout: 'Connection timed out. Please try again later.'
                        }));
                    }
                }, 15000);

                return () => {
                    clearTimeout(connectionTimeout);
                    socket.disconnect();
                };
            } catch (error) {
                console.error("Error in connectSocket:", error);
                setLoading({ stats: false, dataBrokers: false, featureToggles: false });
                setErrors(prev => ({
                    ...prev,
                    general: 'An unexpected error occurred.'
                }));
            }
        };

        connectSocket();
    }, [authToken, getToken]);

    // Determine if all data is still loading for the full-page loader
    const isAllLoading = loading.stats || loading.dataBrokers || loading.featureToggles;

    return (
        <div className="dashboard-page">
            <Sidebar />
            <Navbar />
            <div className="dashboard-content">
                <DashboardHeader user={user} />
                <NavigationTabs />
                <div className="dashboard-main">
                    <StatusBarComponent 
                        sitesScanned={dashboardData.stats.sitesScanned} 
                        totalSites={176}
                        lastScanTime={dashboardData.stats.lastScanTime}
                        isScanning={dashboardData.stats.isScanning}
                        nextScanTime={dashboardData.stats.nextScanTime}
                    />
                    <DashboardStats stats={dashboardData.stats} loading={loading.stats} />
                    {loading.dataBrokers ? (
                        <div className="loading-state">
                            <FaSpinner className="spinner" /> Loading Data Brokers...
                        </div>
                    ) : (
                        <DataBrokerListComponent dataBrokerList={dashboardData.dataBrokerList} loading={loading.dataBrokers} />
                    )}
                    {dashboardData.brokerNames.length > 0 && (
                        <div className="broker-matches">
                            <h3>Broker Matches Found:</h3>
                            <ul>
                                {dashboardData.brokerNames.map((name, index) => (
                                    <li key={index}>{name}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <FeatureToggles toggles={dashboardData.featureToggles} loading={loading.featureToggles} />
                    <DataPointsComponent />
                </div>
                <Footer />
                {/* Display Errors */}
                <div className="error-messages">
                    {errors.connection && <p className="error">Connection Error: {errors.connection}</p>}
                    {errors.socket && <p className="error">Socket Error: {errors.socket}</p>}
                    {errors.timeout && <p className="error">Timeout: {errors.timeout}</p>}
                    {errors.disconnect && <p className="error">Disconnected: {errors.disconnect}</p>}
                    {errors.general && <p className="error">General Error: {errors.general}</p>}
                </div>
            </div>
            {/* Full-Page Loader */}
            {isAllLoading && (
                <div className="full-page-loader">
                    <FaSpinner className="spinner" />
                </div>
            )}
        </div>
    );
};

export default Dashboard;
