import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setToken } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const fetchSessionData = async () => {
      const sessionId = new URLSearchParams(location.search).get('session_id');
      console.log('Session ID:', sessionId);

      if (!sessionId) {
        console.error('No session ID found in URL');
        setError('No session ID found. Please try again or contact support.');
        setProcessing(false);
        return;
      }

      try {
        console.log('Fetching session data...');
        const response = await fetch(`https://cleandata.fly.dev/api/session/${sessionId}`);
        console.log('Response status:', response.status);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Session data received:', data);

        if (data.token) {
          console.log('Token received:', data.token);
          setToken(data.token);
          console.log('Token set, navigating to edit-info');
          navigate('/edit-info');
        } else {
          console.error('No token received in session data');
          setError('Payment successful, but login failed. Please contact support.');
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
        setError(`Error processing payment: ${error.message}. Please try again or contact support.`);
      } finally {
        setProcessing(false);
      }
    };

    fetchSessionData();
  }, [location.search, navigate, setToken]);

  const handleManualEditInfoNavigation = () => {
    console.log('Manually navigating to edit-info');
    navigate('/edit-info');
  };

  if (processing) {
    return <div>Processing your payment... Please wait.</div>;
  }

  if (error) {
    return (
      <div>
        <h2>Payment Processing Notice</h2>
        <p>{error}</p>
        <p>Your payment may have been successful, but we couldn't verify it automatically.</p>
        <button onClick={handleManualEditInfoNavigation}>Proceed to Edit Info</button>
        <button onClick={() => navigate('/')}>Go to Home</button>
      </div>
    );
  }

  return <div>Payment successful! Redirecting to edit info page...</div>;
};

export default SuccessPage;
