import React, { useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ToggleSwitch from './ToggleSwitch';
import './PaymentForm.css';

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const [cardholderName, setCardholderName] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: cardholderName,
                    email: email,
                },
            });

            if (error) {
                console.error('Error creating payment method:', error);
                setErrorMessage(error.message);
                return;
            }

            const response = await fetch('https://cleandata.fly.dev/create-payment-intent', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    amount: 17995,
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                }),
            });

            const responseData = await response.json();
            if (!response.ok) {
                console.error('Error creating payment intent:', responseData);
                setErrorMessage('Error creating payment intent: ' + (responseData.error || 'Unknown error'));
                return;
            }

            if (responseData.error) {
                console.error('Error creating payment intent:', responseData.error);
                setErrorMessage(responseData.error);
                return;
            }

            if (responseData.requiresAction) {
                const { error: confirmError } = await stripe.confirmCardPayment(responseData.paymentIntentClientSecret);
                if (confirmError) {
                    console.error('Error confirming card payment:', confirmError);
                    setErrorMessage(confirmError.message);
                    return;
                }
                alert('Payment successful!');
            } else {
                alert('Payment successful!');
            }
        } catch (error) {
            console.error('Error during payment process:', error);
            setErrorMessage('Error during payment process: ' + error.message);
        }
    };

    return (
        <form className="payment-form" onSubmit={handleSubmit}>
            <div className="form-row">
                <input
                    type="text"
                    placeholder="First Name"
                    className="input-field"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Last Name"
                    className="input-field"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>
            <input
                type="email"
                placeholder="Email Address"
                className="input-field short-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="text"
                placeholder="Cardholder Name"
                className="input-field"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
            />
            <div className="stripe-elements-container">
                <CardNumberElement className="stripe-element card-number-element" />
                <div className="form-row">
                    <div className="stripe-element-wrapper">
                        <CardExpiryElement className="stripe-element" />
                    </div>
                    <div className="stripe-element-wrapper">
                        <CardCvcElement className="stripe-element" />
                    </div>
                </div>
            </div>
            <div className="total-amount">Total $179.95/year</div>
            <div className="toggle-row">
                <label className="toggle-label">I have read and agree to the Terms of Service and Privacy Policy.</label>
                <ToggleSwitch />
            </div>
            <div className="toggle-row">
                <label className="toggle-label">Cancel trial before [date] to not be charged.</label>
                <ToggleSwitch />
            </div>
            <button type="submit" className="submit-button" disabled={!stripe}>
                Get Protected
            </button>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
    );
};

export default PaymentForm;
