import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';  // Add this import

// Create AuthContext
export const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);
    const [authError, setAuthError] = useState(null);

    // Function to retrieve token from localStorage
    const getToken = useCallback(() => {
        const token = localStorage.getItem('token');
        console.log('getToken called, token:', token ? 'exists' : 'null');
        return token;
    }, []);

    // Function to handle user login
    const setToken = useCallback((token) => {
        console.log('setToken called with token:', token ? 'exists' : 'null');
        if (token) {
            localStorage.setItem('token', token);
            try {
                const decodedToken = jwtDecode(token);
                console.log('Decoded token:', decodedToken);
                setUser({
                    id: decodedToken.userId,
                    email: decodedToken.email,
                    name: decodedToken.name
                });
                setAuthLoading(false); // Add this line
            } catch (error) {
                console.error('Error decoding token:', error);
                setAuthError('Invalid token format');
                setAuthLoading(false); // Add this line
            }
        } else {
            localStorage.removeItem('token');
            setUser(null);
            setAuthLoading(false); // Add this line
        }
    }, []);

    // Function to handle user logout
    const logout = useCallback(() => {
        console.log('Logout called');
        setToken(null);
    }, [setToken]);

    // Function to refresh user data
    const refreshUserData = useCallback(async () => {
        console.log('refreshUserData called');
        const token = getToken();
        if (!token) {
            console.log('No token found, setting authLoading to false');
            setAuthLoading(false);
            return;
        }

        try {
            const response = await axios.get('https://cleandata.fly.dev/api/user-data', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const userData = response.data;
            console.log('User data refreshed:', userData);
            setUser({
                id: userData.id,
                email: userData.email,
                firstName: userData.first_name || '',
                lastName: userData.last_name || '',
            });
        } catch (error) {
            console.error('Error refreshing user data:', error.response?.data || error.message);
            if (error.response?.status === 401) {
                setToken(null); // Clear the invalid token
                setAuthError('Session expired. Please log in again.');
            } else {
                setAuthError(`Failed to refresh user data: ${error.response?.data?.error || error.message}`);
            }
        } finally {
            setAuthLoading(false);
        }
    }, [getToken, setToken]);

    useEffect(() => {
        console.log('AuthProvider useEffect called');
        const token = getToken();
        console.log('Initial token check:', token ? 'exists' : 'null');
        if (token) {
            setToken(token);
        } else {
            setAuthLoading(false);
        }
    }, [getToken, setToken]);

    // Function to check if the token is expired
    const isTokenExpired = useCallback(() => {
        const token = getToken();
        if (!token) return true;

        try {
            const decodedToken = jwtDecode(token);
            const isExpired = decodedToken.exp * 1000 < Date.now();
            console.log('Token expiration check:', isExpired ? 'expired' : 'valid');
            return isExpired;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    }, [getToken]);

    // Function to get a valid token (refreshing if necessary)
    const getValidToken = useCallback(async () => {
        console.log('getValidToken called');
        if (isTokenExpired()) {
            console.log('Token expired, needs refresh');
            // Implement your token refresh logic here
            // For now, we'll just return null if the token is expired
            return null;
        }
        return getToken();
    }, [isTokenExpired, getToken]);

    const value = {
        user,
        authLoading,
        authError,
        getToken: getValidToken,
        setToken,
        logout,
        setAuthError
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};