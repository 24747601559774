import React, { useState, useEffect } from 'react';
import { FaSpinner, FaCircle } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './DataRemovalsPage.css';
import NavigationTabs from '../components/NavigationTabs';
import DataPointsComponent from '../components/DataPointsComponent';
import DataBrokerListComponent from '../components/DataBrokerListComponent';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavBar from '../components/MobileNavbar';
import { Footer } from "../components/Footer";

const auth = '71837-cleandata';

const DataRemovalsPage = () => {
  const defaultFirstName = 'John';
  const defaultLastName = 'Wick';

  // Function to generate URLs based on first and last name
  const generateUrls = (firstName, lastName) => [
    { url: `https://dataveria.com/profile/search?fname=${firstName}&lname=${lastName}`, siteName: 'Dataveria' },
    { url: `https://411.info/people?fn=${firstName}&ln=${lastName}`, siteName: '411.info' },
    { url: `https://www.anywho.com/people/${firstName}%20+${lastName}/`, siteName: 'AnyWho' },
    { url: `https://www.addresses.com/people/${firstName}+${lastName}/`, siteName: 'Addresses.com' },
    { url: `https://arrestfacts.com/ng/search?fname=${firstName}&lname=${lastName}&state=&city=`, siteName: 'ArrestFacts' },
    { url: `https://clubset.com/profile/search?fname=${firstName}&lname=${lastName}&state=&city=&fage=None`, siteName: 'Clubset' },
    { url: `https://clustrmaps.com/persons/${firstName}-${lastName}`, siteName: 'ClustrMaps' },
    { url: `https://www.corporationwiki.com/search/results?term=${firstName}%20${lastName}`, siteName: 'Corporation Wiki' },
    { url: `https://fastpeoplesearch.io/search?first_name=${firstName}&last_name=${lastName}&state=`, siteName: 'FastPeopleSearch.io' },
    { url: `https://freepeopledirectory.com/name/${firstName}-${lastName}/`, siteName: 'FreePeopleDirectory' },
    { url: `https://neighbor.report/${firstName}-${lastName}`, siteName: 'Neighbor Report' },
    { url: `https://www.peekyou.com/${firstName}_${lastName}`, siteName: 'PeekYou' },
  ];

  // Initialize with default values
  const [userName, setUserName] = useState({ firstName: defaultFirstName, lastName: defaultLastName });
  const [urls, setUrls] = useState(generateUrls(defaultFirstName, defaultLastName));
  const [loadingStates, setLoadingStates] = useState({});
  const [fullImageIndex, setFullImageIndex] = useState(-1);
  const [rippleEffects, setRippleEffects] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update isMobile on window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Initialize loadingStates when urls change
  useEffect(() => {
    const initialLoadingStates = {};
    urls.forEach((item) => {
      initialLoadingStates[item.url] = true;
    });
    setLoadingStates(initialLoadingStates);
  }, [urls]);

  // Fetch user data and update URLs
  useEffect(() => {
    const fetchUserName = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.warn('No token found, using default user name');
        return;
      }

      try {
        const response = await fetch('https://cleandata.fly.dev/api/user-data', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.error('Failed to fetch user data, status:', response.status);
          return;
        }

        const user = await response.json();
        const firstName = user.first_name || defaultFirstName;
        const lastName = user.last_name || defaultLastName;
        setUserName({ firstName, lastName });
        updateUrls(firstName, lastName);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserName();
  }, []);

  // Update URLs and reset loading states
  const updateUrls = (firstName, lastName) => {
    const newUrls = generateUrls(firstName, lastName);
    setUrls(newUrls);
    // The loadingStates will be reset by the useEffect that depends on urls
  };

  const handleImageLoad = (url) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [url]: false,
    }));
  };

  const handleImageClick = (url) => {
    const index = urls.findIndex((item) => item.url === url);
    setFullImageIndex(index);
    setRippleEffects((prev) => ({ ...prev, [url]: true }));
    setTimeout(() => {
      setRippleEffects((prev) => ({ ...prev, [url]: false }));
    }, 600);
  };

  return (
    <>
      {isMobile ? <MobileNavBar /> : <Navbar />}
      <div className="data-leak-page">
        <Sidebar />
        <div className="content-wrapper">
          <div className="data-leak-header">
            <h1>{`${userName.firstName} ${userName.lastName}'s Privacy Reports`}</h1>
            <nav className="breadcrumb">Home / Data Removals</nav>
          </div>
          <NavigationTabs />
          <DataPointsComponent />

          <div className="data-points-section">
            <h2>Your Data Removal Screenshots</h2>
            <div className="removal-images-container">
              {urls.map((item) => (
                <div
                  key={item.url}
                  className="image-preview"
                  onClick={() => handleImageClick(item.url)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleImageClick(item.url);
                  }}
                  role="button"
                  tabIndex={0}
                  aria-label={`View full image of ${item.siteName}`}
                >
                  {loadingStates[item.url] && <FaSpinner className="loading-spinner" />}
                  <img
                    key={item.url} // Ensures React re-renders when url changes
                    src={`https://image.thum.io/get/auth/${auth}/noanimate/fullpage/maxAge/0/width/600/?url=${encodeURIComponent(
                      item.url
                    )}&timestamp=${Date.now()}`} // Prevent caching
                    alt={`Preview of ${item.siteName}`}
                    onLoad={() => handleImageLoad(item.url)}
                    onError={(e) => {
                      e.target.src = '/path-to-fallback-image.png';
                      handleImageLoad(item.url);
                    }}
                    style={{ display: loadingStates[item.url] ? 'none' : 'block' }}
                    loading="lazy"
                  />
                  {/* Overlay with site name and live recording icon */}
                  <div className="image-overlay">
                    <FaCircle className="live-icon" />
                    <span className="site-name">{item.siteName}</span>
                  </div>
                  {rippleEffects[item.url] && <div className="image-removal-effect"></div>}
                </div>
              ))}
            </div>
          </div>

          {fullImageIndex >= 0 && urls[fullImageIndex] && (
            <Lightbox
              mainSrc={`https://image.thum.io/get/auth/${auth}/noanimate/fullpage/maxAge/0/?url=${encodeURIComponent(
                urls[fullImageIndex].url
              )}&timestamp=${Date.now()}`}
              onCloseRequest={() => setFullImageIndex(-1)}
              imageTitle={`${urls[fullImageIndex].siteName}`}
              enableZoom={true}
              reactModalStyle={{ overlay: { zIndex: 1400 } }}
            />
          )}

          <DataBrokerListComponent />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DataRemovalsPage;
