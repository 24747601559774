// StatusBarComponent.js

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './StatusBarComponent.css';

const StatusBarComponent = ({ sitesScanned, totalSites = 176, lastScanTime, isScanning, nextScanTime }) => {
    // Convert sitesScanned and totalSites to numbers
    const sitesScannedNum = Number(sitesScanned) || 0;
    const totalSitesNum = Number(totalSites) || 1; // Avoid division by zero

    // Calculate percentage
    const percentage = Math.min((sitesScannedNum / totalSitesNum) * 100, 100).toFixed(2);

    useEffect(() => {
        console.log('StatusBarComponent Props:', { sitesScanned, totalSites, lastScanTime, isScanning, nextScanTime });
        console.log('Percentage:', percentage);
    }, [sitesScanned, totalSites, lastScanTime, isScanning, nextScanTime, percentage]);

    // Determine message
    let message = '';
    if (isScanning) {
        message = 'Scanning in progress...';
    } else if (sitesScannedNum >= totalSitesNum) {
        message = 'Scanning completed.';
    } else if (sitesScannedNum > 0) {
        message = 'Scanning in progress...';
    } else {
        message = 'Starting...';
    }

    return (
        <div className={`status-bar-container ${isScanning ? 'scanning' : ''}`}>
            <div className="status-bar-title">Scan Progress</div>
            <div className="progress-bar-container">
                <div
                    className="progress-bar"
                    style={{ width: `${percentage}%` }}
                ></div>
                <div className="progress-text">
                    {sitesScannedNum} / {totalSitesNum} Sites Scanned ({percentage}%)
                </div>
            </div>
            <div className="status-details">
                <div className="status-text">
                    <p><strong>Status:</strong> {message}</p>
                </div>
                {nextScanTime && (
                    <div className="scan-times">
                        <p><strong>Next Scan:</strong> {new Date(nextScanTime).toLocaleString()}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

StatusBarComponent.propTypes = {
    sitesScanned: PropTypes.number.isRequired,
    totalSites: PropTypes.number,
    lastScanTime: PropTypes.string,
    isScanning: PropTypes.bool,
    nextScanTime: PropTypes.string
};

export default StatusBarComponent;
