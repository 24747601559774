import React from 'react';
import './DashboardStats.css';

const DashboardStats = ({ stats, error, loading }) => {
    if (loading) {
        return <div className="loading-message">Loading stats...</div>;
    }

    return (
        <div className="dashboard-stats-container">
            {error && <div className="error-message">{error}</div>}
            <div className="dashboard-stat-box">
                <div className="stat-header">Data Broker Exposure</div>
                <div className="stat-number">{stats.dataBrokerExposure}</div>
            </div>
            <div className="dashboard-stat-box">
                <div className="stat-header">Sites Scanned</div>
                <div className="stat-number">{stats.sitesScanned}</div>
            </div>
            <div className="dashboard-stat-box">
                <div className="stat-header">Profiles Found</div>
                <div className="stat-number">{stats.profilesFound}</div>
            </div>
        </div>
    );
};

export default DashboardStats;