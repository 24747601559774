import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import context
import laptopImage from '../assets/laptop-image.png'; // Corrected path
import './LoginPage.css';

const LoginPage = () => {
    const { handleLogin } = useContext(AuthContext); // Access login handler
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch('https://cleandata.fly.dev/send-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setOtpSent(true);
                console.log('OTP sent to:', email);
            } else {
                const errorText = await response.text();
                setError(`Error sending OTP: ${errorText}`);
            }
        } catch (error) {
            setError('Failed to send OTP. Please try again.');
            console.error('Error sending OTP:', error.message);
        }

        setLoading(false);
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch('https://cleandata.fly.dev/verify-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.token);
                handleLogin(email, data.token); // Use context to log in
                console.log('OTP verified, login successful!');
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                const errorText = await response.text();
                setError(`Error verifying OTP: ${errorText}`);
            }
        } catch (error) {
            setError('Failed to verify OTP. Please try again.');
            console.error('Error verifying OTP:', error.message);
        }

        setLoading(false);
    };

    return (
        <div className="login-page">
            <div className="left-section">
                <div className="logo">
                    <h1>Cleandata.me<sup>™</sup></h1>
                </div>
                <div className="welcome-message">
                    <h2>Sign In To Get Protected</h2>
                </div>
                {!otpSent ? (
                    <form onSubmit={handleSendOtp} className="login-form">
                        <div className="input-group">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="input-field"
                            />
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <button type="submit" className="login-button" disabled={loading}>
                            {loading ? 'Sending OTP...' : 'Send OTP'}
                        </button>
                    </form>
                ) : (
                    <form onSubmit={handleVerifyOtp} className="login-form">
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                className="input-field"
                            />
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <button type="submit" className="login-button" disabled={loading}>
                            {loading ? 'Verifying OTP...' : 'Verify OTP'}
                        </button>
                    </form>
                )}
                <div className="login-footer">
                    <a href="/forgot-password" className="forgot-password">Forgot your password?</a>
                    <span>Not a subscriber yet? <a href="/signup" className="signup-link">Sign up</a></span>
                </div>
            </div>
            <div className="right-section">
                <img src={laptopImage} alt="Dashboard Preview" className="laptop-image" />
            </div>
        </div>
    );
};

export default LoginPage;
